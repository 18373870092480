@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.credit {

    &:before {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 6px;
        border-radius: 50%;
    }

    &.credit-low:before {
        background-color: #FF5A79;
        animation: blinker 2s linear infinite;
    }

    &.credit-ok:before {
        background-color: #8FCC0A;
    }
}
