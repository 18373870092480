@keyframes giftoverlay-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes giftoverlay-popup {
    from {
        transform: scale(0);
    }

    5% {
        transform: scale(1.2);
    }

    10% {
        transform: scale(1);
    }

    95% {
        transform: scale(1);
        opacity: 1;
    }

    to {
        transform: scale(1.5);
        opacity: 0;
    }
}
