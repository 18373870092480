body, html {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    background-color: #000;
}

.grecaptcha-badge {
    visibility: hidden;
}

#root {
    height: 100%;
    width: 100%;
    overflow-y: auto;
}

#nanoplayer-placeholder,
#visitx-placeholder {
    position: fixed;
    width: 100px;
    height: 100px;
    overflow: hidden;
    left: -110px;
}

.right-on-idle, .left-on-idle {
    transition: transform 500ms !important;
}

.idle {
    & .left-on-idle {
        transform: translateX(-100vw);
    }

    & .right-on-idle {
        transform: translateX(100vw);
    }

    &.system .muted,
    &.system .delayed {
        transition-delay: 8s !important;
    }
}

li img[src*="sexchat-full.svg"] {
    object-fit: none;
}
